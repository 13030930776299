import React, {useState} from "react";
import axios from "axios";


/*
Questions:

1. Is it better to have 1 hic in the main form or have each component have an hic?


*/

export const Space = (props) => {
      /// OE??!!! Just a space
              return(
                  <>&nbsp;</>
              )
          }
          

  
export const Pager = (props) => {

        var nPages = props.nRows/props.pageSize
        var o = Number(props.offset ? props.offset : 0)
        var p = o-props.pageSize
        if (p < 0)
            p = 0
        var n = o+Number(props.pageSize)

        if (n > props.nRows-1)
            n = props.nRows-1
        if (nPages > 10)
          nPages = 10; 

//    console.log("rows", props.nRows)

        var middle = []
        for (var i = 0; i < nPages; i++)
        {
            if (i*props.pageSize != props.offset)
                middle.push(<a key={i} className="w3-button" href={props.link+"&o="+i*props.pageSize}>{i+1}</a>)
            else
                middle.push(<a key={i} className="w3-button" style={{color: "red"}} href={props.link+"&o="+i*props.pageSize}>{i+1}</a>)

        }
    
        return(
        <div className="w3-bar">
        <a className="w3-button" href={props.link+"&o="+p}>Previous</a>
        {middle}
        <a className="w3-button" href={props.link+"&o="+n}>Next</a>
      </div>
        )
    
    }
    
export const UrlSelector = (props) => {
console.log("w", props.w)

    var links = [];
    for (var i = 0; i < props.links.length; i+= 2) {
      // ??? Note the 2* is a little confusing.  Try to change it.
      
      var c = (i == 2*props.w) ? "#0000ff" : "#ff0000";
      
        links.push(<a key={2*i} style={{color:c}} href={props.links[i+1]}>{props.links[i]}</a>);
        links.push(<Space key={2*i+1}/>);
    }

    return (

        <>{links}</>
    )

}
    

export const ButtonList = (props) => {

    var list = [];
    var i = 0;
    props.list.map((item) => {
        list.push(<div key={i++}>{item.name} <button type="button" onClick={() => props.onClick(item.id)}>{props.title}</button></div>)
    })
    return <div>{list}</div>
}



class Modal1 extends React.Component {

    render()
    {
        return (
          <div className={this.props.show ? "modal display-block" : "modal display-none"}>
            <section className="modal-main" id={this.props.id}> 
            <div className="container">
      
              {this.props.children}
              <button onClick={() => {
                this.props.handleClose();
              
              }}>Close</button>
              </div>
              <br/>
      
            </section> 
          </div>
        );
      }
    
    }
    
export const Modal = Modal1;
    
export const Drawer = (props) => {
    return <div style={{position: "relative", display: "inline-block"}}>
    <span style={{fontSize:"30px",cursor:"pointer"}} onClick={() => {
      
      var x = document.getElementById("Demo1");
  if (x.className.indexOf("w3-show") == -1) {
  x.className += " w3-show";
  } else { 
  x.className = x.className.replace(" w3-show", "");
  }
    
    }}>&#9776;</span>
    <div id="Demo1" style={{display: "none", position: "absolute"}}  className="w3-dropdown-content w3-bar-block w3-border w3-hide">
    <div>
        {props.children}
  </div></div></div>
  }
  
  export const getLocalDate = function(d1)
  {
    return new Date(d1).toLocaleDateString();
  }
  
  export const Section = (props) => {
  
    return <div>
      {props.children}
    </div>
  }
  
  export const Page = (props) => {
  
    return <div><center>
      {props.children}
      </center>
    </div>
  }
  
  
  export const SectionList = (props) => {
  
    const [count, setCount] = useState(0);
  
  
    let list;
    
    if (props.list)
    {
      let style;
      list = [];
      var i = 0;
      props.list.map(item => {
        if (props.sel != item)
          style = {};
        else
          style = {color: "red"};
        list.push(<div key={i}><a href="#" onClick={() => {
          if (props.onSelect)
          {
            props.onSelect(item);
          }
  //        alert(item) 
        }
        }><span style={style}>{item}</span></a><br/></div>);
  //      list.push(<br/>);
        i++;
      })
      
    }
    else
    {
      list = <div>1.<br/>2.<br/>3.<br/></div>;
  
    }
    return <div style={{textAlign: "left"}}>
      {list}
    </div>
  
  
  }
  
  /*
  export class SectionList1 extends React.Component
  {
  
  render()
  {
    let list;
    if (this.props.list)
    {
      list = [];
      this.props.list.map(item => {
        list.push(<a href="#" onClick={() => {
          if (this.props.onSelect)
          {
            this.props.onSelect(item);
          }
  //        alert(item) 
        }
        }>{item}</a>);
        list.push(<br/>);
      })
      
    }
    else
    {
      list = <div>1.<br/>2.<br/>3.<br/></div>;
  
    }
    return <div style={{textAlign: "left"}}>
      {list}
    </div>
  
  
  }
  }
  
  export const SectionList = SectionList1;
  */
  
  
  class select1 extends React.Component
  {
  // ???!!! consider making options a map to avoid dup keys
    componentDidMount()
    {
      
    }
    render()
    {
      var list = [];
  //    list.push(<option>Please Select</option>)
  
      if (this.props.options) {
      this.props.options.map(item => {
        list.push(<option key ={item.id} value={item.id}>{item.name}</option>);
      })
    }
      return (<>
      <select name={this.props.name} value={this.props.value} onChange={this.props.onChange}>
        {list}
      </select>
      </>);
    }
  
  }
  
  export const Select = select1;
  
  
  
  const currentDate = new Date();
  
  const categories = [
    { name: "Last 5 Years", dateBegin: findDate(1, 5*12), dateEnd: findDate() },
    { name: "All", dateBegin: null, dateEnd: null},
    { name: "Custom", dateBegin: null, dateEnd: null },
    { name: "Today", dateBegin: findDate(), dateEnd: findDate() },
    { name: "Yesterday", dateBegin: findDate(1), dateEnd: findDate(1) },
    { name: "This Week", dateBegin: findDate(currentDate.getDay()), dateEnd: findDate() },
    { name: "Last Week", dateBegin: findDate(7 + currentDate.getDay()), dateEnd: findDate(7 - currentDate.getDay()) },
    { name: "Past 7 Days", dateBegin: findDate(7), dateEnd: findDate() },
    { name: "This Month", dateBegin: findDate(currentDate.getDate() - 1), dateEnd: findDate() },
    { name: "Last Month", dateBegin: findDate(1, 1), dateEnd: findDate(currentDate.getDate()) },
    { name: "Past 30 Days", dateBegin: findDate(30), dateEnd: findDate() },
    { name: "Past 6 Months", dateBegin: findDate(1, 6), dateEnd: findDate() },
    { name: "This Year", dateBegin: findDate(1, currentDate.getMonth()), dateEnd: findDate() },
    { name: "Past 12 Months", dateBegin: findDate(1, 12), dateEnd: findDate() },
  ];
  
  function findDate(dayBuffer, monthBuffer) {
      let curr = new Date();
  
      if (dayBuffer) {
        if (monthBuffer) {
          curr.setDate(dayBuffer);
          curr.setMonth(curr.getMonth() - monthBuffer);
        } else {
          curr.setDate(curr.getDate() - dayBuffer);
        }
      }
  
      return curr.toISOString().substr(0,10);
  }
  
  
  class datePicker extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        category: this.props.cat? this.props.cat : 0,
        dateBegin: this.props.startDate? this.props.startDate : categories[0].dateBegin,
        dateEnd: this.props.endDate? this.props.endDate: categories[0].dateEnd,
      };
      this.props.parent.setState({date1:this.state.dateBegin, date2: this.state.dateEnd})
      if (this.props.setDateFilter)
      {
        this.setDateFilter = this.props.setDateFilter.bind(this);
        this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
      }
    }
  
    componentDidMount() {
      if (this.setDateFilter)
      {
        this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
  
      }
    }
  
    handleSelect(event) {
      const value = event.target.value;
  
      console.log(value)
      // Future proofing for potental cases
      if (!categories[value].dateBegin) {
        document.getElementById("dateBegin").value = "";
      }
      if (!categories[value].dateEnd) {
        document.getElementById("dateEnd").value = "";
      }
  
      // Callback to set the dates in the parent once the initial setState function is finished
      this.props.parent.setState({date1: categories[value].dateBegin, date2: categories[value].dateEnd });
      this.setState({ category: value, dateBegin: categories[value].dateBegin, dateEnd: categories[value].dateEnd }, () => {
        if (this.setDateFilter)
          this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
      });
    }
  
    // Man, callbacks really suck, don't they?
    // (Closure just so only silly callback will work correctly)
    checkDate(name, oldDate) {
      return () => {
        if (Date.parse(this.state.dateBegin) > Date.parse(this.state.dateEnd)) {
          this.setState({[name]: oldDate});
          alert("Invalid Date Range");
        } else {
          this.setState({category: 2})
          this.props.parent.setState({date1: this.state.dateBegin, date2: this.state.dateEnd });
  
          if (this.setDateFilter)
            this.setDateFilter(this.state.dateBegin, this.state.dateEnd);
        }
      }
    }
  
    handleChange(event) {
      const oldDate = this.state[event.target.name];
  
      this.setState({[event.target.name]: event.target.value}, this.checkDate(event.target.name, oldDate));
      
    }
  
    handleSubmit(event) {
      event.preventDefault();
      
      this.setDateFilter(this.state.dateBegin, this.state.dateEnd, this.state.category);
    }
  
    render() {
      return (
        <div>
          Date Range:
          <form onSubmit={this.handleSubmit.bind(this)}>
            <label>
              Category:{" "}
              <select
                value={this.state.category}
                onChange={this.handleSelect.bind(this)}
              >
                {categories.map((item, index) => (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              {" "}Start Date:{" "}
              <input
                id="dateBegin"
                type="date"
                name="dateBegin"
                onChange={this.handleChange.bind(this)}
                value={this.state.dateBegin}
              />
            </label>
            <label>
              {" "}End Date:{" "}
              <input
                id="dateEnd"
                type="date"
                name="dateEnd"
                onChange={this.handleChange.bind(this)}
                value={this.state.dateEnd}
              />
            </label>
            {/*
              {" "}
              <input type="submit" value="Set" />
            */}
          </form>
        </div>
      );
    }
  }
  
  export const DatePicker = datePicker
  
  export const AnimModal = (props) => {
  
  return (
  <div id="myModal" className={props.show ? "modal display-block" : "modal display-none"}>
  
  
  <div className="modal-content">
    <div className="modal-header">
      <span className="close" onClick={() => {
        props.close();
      }}>&times;</span>
      <h2>Modal Header</h2>
    </div>
    <div className="modal-body">
      <p>Some text in the Modal Body</p>
      <p>Some other text...</p>
    </div>
    <div className="modal-footer">
      <h3>Modal Footer</h3>
    </div>
  </div>
  
  </div>)
  
  }
  
  
  
  export const UploadFile = (props) => {

    const [progress, setProgress] = useState(0);

function onFile() {
    var file = document.getElementById("file_upload").files[0];
  
    var formData = new FormData();
    console.log(file);
    
    formData.append('file', file);
    formData.append('id', props.id) 
    formData.append("dw", "saveFile")
    axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      data: formData,
      url: props.link, // route name
//      baseURL: g_link, //local url
      onUploadProgress: progress => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
//        setUploadPercentage(uploadPercentage.toFixed(2));
        this.setState({progress: uploadPercentage+"%"});
//        console.log("total size in MB ==> ", totalSizeInMB);
//        console.log("uploaded size in MB ==> ", loadedSizeInMB);
      },
      encType: "multipart/form-data",
    }).then((res) => {
/*      if (!this.state.id && res.code == 200)
        {
          this.setState({id: res.result.id, progress: "0%"});
        }
        this.setState({progress: "0%"}); */
        alert("File was uploaded");
    }).catch(error => { 
      alert(error);
    });    

  }

    return <>
<input type="file" className="form-control-file" id="file_upload"  onChange={onFile} /><br/>
<div className="w3-grey" style={{height: "24px", width: progress}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>

<img src={props.img} width="50px" alt="profile image"/>



    </>
  }

  export const HoriList = (props) => {
  
    const [count, setCount] = useState(0);
  
  
    let list;
    
    if (props.list)
    {
      let style;
      list = [];
      var i = 0;
      props.list.map(item => {
        if (props.sel != item)
          style = {};
        else
          style = {color: "red"};
          style.margin = "5px";

        list.push(<div key={i} style={{float: "left"}}><a href="#" onClick={() => {
          if (props.onSelect)
          {
            props.onSelect(item);
          }
  //        alert(item) 
        }
        }><span style={style}>{item}</span></a></div>);
  //      list.push(<br/>);
        i++;
      })
      
    }
    else
    {
      list = <div>1.<br/>2.<br/>3.<br/></div>;
  
    }
    return <div style={{textAlign: "left", float: "left"}}>
      {list}
    </div>
  
  
  }



  export const TagList = (props) => {

    const [count, setCount] = useState(0);
    const [tag, setTag] = useState("0");

//    const [selected, setSelected] = useState([{id: 1, name: "blah!"}]);

    /*
    props: def1, maxCount, list
    
    */

    
    var list = [];

    var map1 = {}
//    console.log("tags", props.selected);

list.push(<option  key="0" value="0">Please Select</option>);

    props.list.map(item => {
      map1[item.id] = item.name;
      list.push(<option  key={item.id} value={item.id}>{item.name}</option>);
    })

    var s1 = [];

    var i = 0;
    props.selected.map((tagId) => {

      s1.push(<div key={i} style={{float: "left"}} >{map1[tagId]} <button style={{padding: "1px", fontSize: "10px"}} onClick={() => {

        var n = props.selected.indexOf(tagId)

        props.selected.splice(n, 1);

        setCount(count+1);


      }}>X</button><Space/></div>)
      i++;
    })

        return (<div>
    {s1}
    
          <select name="tag" value={tag} onChange={(event) => {

            setTag(event.target.value);


          }}>
            {list}
          </select><Space/> <button onClick={() => {
            
//            .push(<>La La!</>)
            if (props.selected.length >= 3)
              return;

              if (tag == "0")
                return;
                
            if (props.selected.indexOf(tag) != -1)
              return;

            props.selected.push(tag)

            setCount(count+1);


          }}>Add</button>
        
        
        
        </div>)
    
      }
    
   export const CheckBox = (props) => {

        return <>
          <input type="checkbox" name={props.name} checked={props.checked} onChange = {props.hic}  /><Space/>
          <label htmlFor={props.name}>{props.prompt}</label>
        </>


   }


   //https://legacy.reactjs.org/docs/typechecking-with-proptypes.html#default-prop-values

   export const CheckBoxTable = (props) => {

    return (
      <tr>
      <td><label htmlFor={props.name}>{props.prompt}</label></td>
      <td><input name={props.name} type="checkbox" checked={props.checked} onChange = {props.hic} /></td>
      
      </tr>

    )
}

export const Password = (props) => {

  return (
    <tr>
    <td><label htmlFor={props.name}>{props.prompt}</label></td>
    <td><input name={props.name} type="password" value={props.value} onChange = {props.hic} /></td>
    
    </tr>

  )
}

export const Search = (props) => {

  const [term, setTerm] = useState(props.term);

  return <input name="term" value={term} 

  onKeyDown={async (event) => {
  
  
    if (event.code == "Enter") {
      
      document.location = window.location.origin + "?q="+encodeURI(term);
  
    }
    
  }}
  
  
  onChange={(event) => {
  
  //      console.log(event.target.value)
      setTerm(event.target.value)
  
  }} type="text"/>


}

export const TextPrompt = (props) => {
 
      return(
  <div className="form-group row">
    <label htmlFor="name" className="col-sm-2 col-form-label">{this.props.title}:</label><div className="col-sm-7">
    <input className="form-control" type='text' onChange={props.onChange} id={props.eid} value={props.value} name={props.name} />
    </div>
  </div>
      )
  }
  
export const EmailPrompt = (props) => {

  const [value, setValue] = useState(props.value);

      return(
  <div className="form-group row">
    <label for="name" className="col-sm-2 col-form-label">{props.title}:</label><div className="col-sm-7"><input className="form-control" type='email' id={props.eid} onChange={props.onChange1} value={value} name='name' /></div>
  </div>
      )
  }
  
  