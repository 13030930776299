const React = require("react")
//import { render } from "react-dom";
//import {callUrl} from "./helper"
//const Space = require("./comp_misc").compSpace
//const config = require('./config.js').CONFIG
//const Modal = require('react-modal')
const helper = require('./helper');
const TextPrompt = require("./comp_misc").textPrompt
const CompList = require("./comp_misc").compList
var md5 = require("blueimp-md5");

var g_link = "/user/page?dw=get";

var g_project;
var g_wikiName;

class ListLine extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {
      sen: this.props.sen,
      comment: this.props.comment
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    this.setState({
      [name]: value
    });
    }  
  render()
  {
    var bg = "#33333333";
    if ((this.props.line % 2) == 1)
      bg = "#ffffff"
    return(
<tr style={{backgroundColor: bg}} key={this.props.line}><td width="60%"><input onChange={this.handleChange} name="sen" size="100" type="text" value={this.state.sen}/></td>
    <td>{this.props.sug}</td><td><input type="button" value="Save" onClick={(e) => { 
      
//      alert(md5(this.props.sen))
      var s = this.state.comment;
      helper.postToServer("/user/line_comment", {dw: "add", pid: g_project, body: s, name: g_wikiName, hash: md5(this.state.sen)})
      .then(res => {

      })
      .catch(err => {
        alert(err);
      })
    }
    }/></td><input onChange={this.handleChange} type="text" name="comment" value={this.state.comment} /></tr>
    )
  }
}


class CompAna extends React.Component {
  constructor() {
    super();

    var q = helper.parseQuery();
    g_project = q.project;
    g_wikiName = q.name;

  this.state = {
    id: q.project,
    project: q.project,
    name: q.name,
    page: null,

  };
    this.handleInputChange = this.handleInputChange.bind(this)
    this.save = this.save.bind(this);
  }

save(e, i)
{
//  var hash = md5('value')
  alert(i);
}

fetchPage()
{
  helper.getFromServer('/user/ana?dw=lines&pid='+this.state.project+"&name="+this.state.name)
  .then(res => {

var lines = res.result.lines;
var comments = res.result.comments;
var rows = [];
console.log(res.result);

for (var i = 0; i < lines.length; i++)
{
  if (lines[i] != null)
  rows.push(<ListLine key={i} comment={comments[md5(lines[i].sen)]} line={i} sen={lines[i].sen} sug={lines[i].sug}/>);
  /*
    rows.push(<tr key={i}><td width="60%"><input size="100" type="text" value={lines[i].sen}/></td>
    <td>{lines[i].sug}</td><td><input type="button" value="Save" onClick={(e) => { alert(i)}}/></td></tr>); */
  else
    rows.push(<tr><td>&#960;</td></tr>);
}
this.setState({rows: rows})
})
.catch(err => {
  alert(err)
})

}


componentDidMount() 
{
  this.fetchPage()

}


handleInputChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });

}

  render() {
    document.title = "Scribya Page"

    if (this.state.rows === null) {
return(
      <div>Working on it2</div>);
    } else {

    
return(

<div className="container">
<br/>
<table>
  <tbody>
{this.state.rows}
</tbody>
</table>
	
  
</div>

)
}
  }
}

// onClick={() => { callUrl('logout') }}

export default CompAna
