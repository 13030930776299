//import Select from "react-select"
import "./App.css"
//import 'react-select/dist/react-select.css';

const React = require("react")
const Component = React.Component
//const config = require('./config.js')
const helper = require("./helper")
//const Modal = require('react-modal')

//var goalSelector = null

/*
class FormConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
        }
    
      }

render() {

    return(<div>
        <Modal
          isOpen={this.state.modalIsOpen}
          style={helper.modalStyles}
          contentLabel="Message"
        >
        this.props.note
        </Modal>
        </div>)

}

}

export const formConfirm = FormConfirm;
*/

class TextAreaPrompt extends Component {
 
render()
{
    return(
<div className="form-group row">
<label htmlFor="description" className="col-sm-2 col-form-label">{this.props.title}:</label><div className="col-sm-6">
<textarea  className="form-control"  rows="4" onChange={this.props.onChange} value={this.props.value} id={this.props.eid} name={this.props.name} />
</div>
</div>
    )
}
}

export const textAreaPrompt = TextAreaPrompt

class TextPrompt extends Component {
 
render()
{
    return(
<div className="form-group row">
  <label htmlFor="name" className="col-sm-2 col-form-label">{this.props.title}:</label><div className="col-sm-7">
  <input className="form-control" type='text' onChange={this.props.onChange} id={this.props.eid} value={this.props.value} name={this.props.name} />
  </div>
</div>
    )
}
}

class EmailPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: props.value };
//            name: props.name
      }
 
render()
{
    return(
<div class="form-group row">
  <label for="name" class="col-sm-2 col-form-label">{this.props.title}:</label><div class="col-sm-7"><input class="form-control" type='email' id={this.props.eid} onChange={this.props.onChange1} value={this.props.value} name='name' /></div>
</div>
    )
}
}

export const textPrompt = TextPrompt

class FormContact extends Component {
    constructor(props) {
       super(props);
       this.state = { hasError: false };
   
     }
  
handleSend()
{

    // no need for config.back or api cuz helper does it
    helper.postToServer("/psupport", {email: document.getElementById("email").value, message: document.getElementById("message").value})
    .then(resp => {
        alert("Thanks for your message.  We will be in touch shortly.")
        document.getElementById("email").value = ""
        document.getElementById("message").value = ""

    })
    .catch(err => {
        alert(err)
    })

}

  render()
    {
      return(<div className="container">
      <br/>
      Please enter your message.
      <br/>
      <br/>
      <EmailPrompt title="Email" value={this.state.email} eid="email" />
      <TextAreaPrompt title="Message" value={this.state.message} eid="message"/>
      <input type="button" className="btn btn-primary" onClick={this.handleSend.bind(this)} value="Send"/>
      </div>)
    }
  
  
    } 
  
export const formContact = FormContact


class CompPager extends Component{
constructor(props){
    super(props)
    var nPages = props.nRows/props.pageSize
    var p = props.offset-props.pageSize
    if (p < 0)
        p = 0
    var n = props.offset+props.pageSize
    if (n > props.nRows-1)
        n = props.nRows-1
    this.state = {
        nPages: nPages,
        p: p,
        n: n,
    }

}
render() {
    var middle = []
    for (var i = 0; i < this.state.nPages; i++)
    {
        middle.push(<li key={i} className="page-item"><a className="page-link" href={this.props.link+"?o="+i*this.props.pageSize}>{i+1}</a></li>)
    }

    return(
    <nav aria-label="Page navigation example">
    <ul className="pagination">
    <li className="page-item"><a className="page-link" href={this.props.link+"?o="+this.state.p}>Previous</a></li>
    {middle}
    <li className="page-item"><a className="page-link" href={this.props.link+"?o="+this.state.n}>Next</a></li>
  </ul>
</nav>
    )

}
}

export const compPager = CompPager

class CompSearch extends Component {

    render()
    {
        return(
            <div>Topics:</div>
        )
    }
    }
    
    export const compSearch = CompSearch
    
    class CompSpace extends Component {
    /// OE??!!! Note - this won't work with weird CSS float styles
        render()
        {
            return(
                <>&nbsp;</>
            )
        }
        }
        
    export const compSpace = CompSpace
    export const space = CompSpace;

    
    class CompList extends Component {

        render(){

            return(
                <div className="row mt-3" key="0">{this.props.children}</div>
            )

        }

    }
export const compList = CompList

    class CompListItem extends Component {

        render(){

            return(
                <div className="col-sm">{this.props.children}</div>
            )

        }

    }
export const compListItem = CompListItem


class Modal extends React.Component {

    render()
    {
        return (
          <div className={this.props.show ? "modal display-block" : "modal display-none"}>
            <section className="modal-main" id={this.props.id}>
            <div className="container">
      
              {this.props.children}
              <button onClick={this.props.handleClose}>Close</button>
              </div>
              <br/>
      
            </section>
          </div>
        );
      }
    
    }
    
export const modal = Modal;

class FilterBox extends React.Component {

    
    render()
    {
        var options = [];
        options.push(<option>Please select a {this.props.prompt}</option>);
        this.props.options.map(item => {
            options.push(<option value={item.id}>{item.name}</option>);
        })
        return (
        <div>{this.props.prompt}: <select onChange={this.props.parent.filter.bind(this.props.parent)}  >{options}</select></div>
        );
    }

}

export const filterBox = FilterBox;