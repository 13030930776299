import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import Login from "./Login"
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
const config = require("./config");

const Header = require("./header").default;
const helper = require("./helper");

//ReactDOM.render(<App />, document.getElementById('root'));

/*
class Header extends React.Component
{
render()
{
    return(<div>Hello!</div>);
}
}
*/
/*
<ul>
<li>
  <Link to="/">Home</Link>
</li>
<li>
  <Link to="/about">About</Link>
</li>
<li>
  <Link to="/topics">Topics</Link>
</li>
</ul>

<hr />
*/

function checkName()
  {
    if (sessionStorage.getItem("name"))
      return true;
    sessionStorage.setItem("rd", document.location)
    return false;
  }
  
const PrivateRoute = ({ component: Component, ...rest }) => 
(
 <Route {...rest} render={(props) => (

	  checkName()	? <Component {...props} />
			: <Redirect to='/login' />
  )
} />
)


function Home()
{
    return (
        <div>Welcome<br/><br/></div>
    );

}

function Logout() {


    var link = config.back+"/logout"
      fetch(link, {
      method: 'POST', credentials: "same-origin"})
      .then(res => res.json())
        .catch(error => 
            console.error('Error:', error))
        .then(function(response) 
        { 

      })

      sessionStorage.removeItem("name")
      sessionStorage.removeItem("uid")
      sessionStorage.removeItem("admin")
      document.location = "/home"
    
    }
  

class CompText extends React.Component {
      constructor() {
        super();
    
        var q = helper.parseQuery();
      this.state = {
        id: q.project,
        project: q.project,
        name: q.name,
    
      };
      }
    
  
    componentDidMount() 
    {
      helper.getFromServer("/user/text?project="+this.state.project+"&name="+this.state.name).then(res => {
        this.setState({text: res.result});
      })
      .catch(err => {
        this.setState({text: "an error has occurred"});
      })
    }
    
    
      render() {
        document.title = "Scribya Text"
    
    return(
    
    <div className="container">
{this.state.text}
      
    </div>
    
    )
      }
    }
    

function route1() {
    return (
      <Router>
<div style={{width:"800px", backgroundColor: "white"}}>
        <Header/>
        <div style={{margin: "20px"}}>
  
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
{/*           <Route path="/about" component={About} /> */}
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />

          <PrivateRoute path="/user/projects" component={require("./user/list_projects").default} /> 
          <PrivateRoute path="/user/page" component={require("./user/form_page").default} />
          <PrivateRoute path="/user/wedit" component={require("./user/form_wedit").default} />
          <PrivateRoute path="/user/classify" component={require("./user/form_classify").default} />          
          <PrivateRoute path="/user/ana" component={require("./comp_ana").default} />
          <PrivateRoute path="/user/text" component={CompText} />

          <PrivateRoute path="/admin/users" component={require("./admin/list_users").default} />

        </div>
        </div>                
      </Router>
    );
  }

ReactDOM.render(route1(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
