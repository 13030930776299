import React, { Component } from 'react';
import "./App"
const config = require('./config.js');

class Header extends Component {

showMenu()
{
  let admin = "";
  let login = <a href="/login" className="w3-bar-item w3-button">Login</a>;
  let user = "";

  console.log(sessionStorage.getItem("roles"));

  const roles = sessionStorage.getItem("roles");

  if (roles && sessionStorage.getItem("name"))
  {
    login = <a href="/logout" className="w3-bar-item w3-button">Logout</a>;
    user = <div><div className="w3-dropdown-hover">
      <button class="w3-button">Projects</button>
      <div className="w3-dropdown-content w3-bar-block w3-border">
    <a href="/user/projects" className="w3-bar-item w3-button">Projects</a>
    </div></div></div>;

    if (roles.indexOf("admin") != -1)
    {
      admin =   admin =  <div className="w3-dropdown-hover">
      <button class="w3-button">Admin</button>
      <div className="w3-dropdown-content w3-bar-block w3-border">
    <a href="/admin/users"  className="w3-bar-item w3-button">Users</a>
    <a href="/api/admin/logs"  className="w3-bar-item w3-button">Logs</a>
    <a href="/admin/bots"  className="w3-bar-item w3-button">Bots</a>
      </div>
    </div>;
    
    }
  }

    return (

      <div className="w3-bar w3-green">
      <a href="/home" className="w3-bar-item w3-button">Home</a>
      <a href="/doc/about" className="w3-bar-item w3-button">About</a>
      <a href="/contact" className="w3-bar-item w3-button">Contact</a>
      {user}
      {admin}
      {login}
      </div> 
    )
    

}

componentDidMount()
{
  document.title = "Scribya"

}
  render() {
    var secure = "";
    console.log(config);
    if (config.back === "https://localhost:3010/api" && sessionStorage.getItem("uid") == null)
    {
        secure = <a href='https://localhost:3010/'>Secure</a>
  
    }


    return (
      <div className="App">
      <div className="w3-container w3-teal">
     <div style={{ maxWidth: '800px'}}>
      <h1>Scribya</h1>
      <h5>Writing tool {sessionStorage.getItem("name")}</h5>{secure}
    </div>
    </div>

    <div  style={{display: 'flex', justifyContent: 'center'}}>{this.showMenu()}</div>
    </div>
    );


  }
}



export default Header;


