import {Space} from "../modules/omwui/misc"
const React = require("react")
//import { render } from "react-dom";
//import {callUrl} from "./helper"
//const Space = require("./comp_misc").compSpace
//const config = require('./config.js').CONFIG
//const Modal = require('react-modal')
const helper = require('../helper');
const TextPrompt = require("../comp_misc").textPrompt
const CompList = require("../comp_misc").compList
const comp = require("../comp_misc");


var g_link = "/user/page?dw=get";

class CompPage extends React.Component {
  constructor() {
    super();

    var q = helper.parseQuery();

  this.state = {
    id: q.id,
    project: q.project,
    name: decodeURI(q.name),
    page: null,

  };
    this.handleInputChange = this.handleInputChange.bind(this)
  }


fetchPage()
{
  helper.getFromServer('/user/page?dw=get&pid='+this.state.project+"&name="+helper.getURI(this.state.name))
  .then(res => {

//  console.log(res.result.body);

var page = helper.renderWiki(res.result, this.state.project);

this.setState({page: page})
})
.catch(err => {
  alert(err)
})

}


componentDidMount() 
{
  this.fetchPage()

}


handleInputChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });

}

  render() {
    document.title = "Scribya Page"

    if (this.state.page === null) {
return(
      <div>Working on it2</div>);
    } else {

      var link = "/user/ana?dw=get&project="+this.state.project+"&name="+this.state.name;
      var link1 = "/api/user/text?project="+this.state.project+"&name="+this.state.name;
      var link2 = "/api/user/html?project="+this.state.project+"&name="+this.state.name;
      var editLink = "/user/wedit?dw=get&project="+this.state.project+"&name="+this.state.name;
    
return(
<div className="container">
<a href={editLink}>Edit</a> <a href={link1} download>Text</a> <a href={link2} download>Html</a> <a href={link}>Analyze</a> 
<Space/><a href={`/user/classify?project=${this.state.project}&name=${this.state.name}`}>Classify</a>
<Space/>
<a href={`/user/page?project=${this.state.project}&name=outline`}>Project</a>
<br/>
<br/>

	{this.state.page}
  <br/>
</div>

)
}
  }
}

// onClick={() => { callUrl('logout') }}

export default CompPage
