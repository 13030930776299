import React from 'react';

const config = require("./config");

function finishLogin(response, t)
{
// OE!!! This is where to put the TOS agreement!!!!
// check response.tos
//console.log(response);

	if (response.result === "OK")
	{

//    sessionStorage.setItem("connection", cookies.get("connect.sid"))
		sessionStorage.setItem("name", response.name);
    sessionStorage.setItem("uid", response.uid);
    sessionStorage.setItem("roles", response.roles);
/*    if (response.roles)
    {
      if (response.roles.indexOf("admin") !== -1)
        sessionStorage.setItem("admin", true)
    } */


//		this.setState({error: "Worked!"});
    if (sessionStorage.getItem("rd"))
//      history.replace(sessionStorage.getItem("rd"))
      document.location = sessionStorage.getItem("rd");
    else
      document.location = "/home";

//      history.replace('/home')

    sessionStorage.removeItem("rd")

	}
	else
	{
		sessionStorage.setItem("name", null)
		t.setState({error: "Sorry!"});
	}


}

class  Login extends React.Component {

    state = {
      email: "",
      password: "",
	error: ""
    };

    handleSubmit = this.handleSubmit.bind(this);


handleSubmit(event) {

//    event.preventDefault();

fetch(config.back+'/login', {
  method: 'POST', credentials: "same-origin",
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    user: document.getElementById("email").value,
    password: document.getElementById("password").value,
  }),
}).then(res => res.json())
.catch(error => console.error('Error:', error))
.then((response) => 
{
//  console.log(this);
  finishLogin(response, this)


});


}

 
  render() {
    document.title = "Scribya login"
 return(
<div className="container">
<br/>

<br/>
<br/>

<div className="col-md-6">
	<input type="text" name="email" className="form-control" id="email" placeholder="you@example.com" autoFocus/><br/>
	<input type="password" name="password" className="form-control" id="password" placeholder="Password"/><br/>

{this.state.error}
  <button onClick={this.handleSubmit.bind(this)} className="btn btn-primary">Login</button>
</div>


<br/>


</div>
 
    );
}
 }

export default Login;


export const checkLogin = async function()
{
/*  if (document.location.pathname != "/ye2")
    document.location = "/ye2"; */

}

