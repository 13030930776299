import {useState, useEffect} from "react";
//import { render } from "react-dom";
//import {callUrl} from "./helper"
//const Space = require("./comp_misc").compSpace
//const config = require('./config.js').CONFIG
//const Modal = require('react-modal')
const helper = require('../helper');

var g_link = "/user/classify";

var g_project;
var g_wikiName;

const ListLine = (props) => {


  function handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    }  

    var bg = "#33333333";
    if ((props.line % 2) == 1)
      bg = "#ffffff"
    return(
<tr style={{backgroundColor: bg}} key={props.line}><td width="60%"><input onChange={handleChange} name="sen" size="100" type="text" value={''}/></td>
    <td>{props.sug}</td><td><input type="button" value="Save" onClick={(e) => { 
      
    }
    }/></td><input onChange={handleChange} type="text" name="comment" value={''} /></tr>
    )

}


export default function(props) {


  const [rows, setRows] = useState([])

    var q = helper.parseQuery();
    var projectId = q.project;
    var name = q.name;


function save(e, i) {
//  var hash = md5('value')
  alert(i);
}




  useEffect(() => {
    helper.getFromServer(g_link+"?projectId="+projectId+"&name="+name)
    .then(res => {
  
  var lines = res.result.lines;
  var rows1 = [];
  for (var i = 0; i < lines.length; i++) {
      rows1.push(<tr key={i}><td>{lines[i]}</td></tr>);
  }
    setRows(rows1);

  })
  .catch(err => {
    alert(err)
  })

  }, [])


 function handleInputChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;


}

 document.title = "Scribya Page"

    if (rows === null) {
return(
      <div>Working on it2</div>);
    } else {

    
return(

<div className="container">
<br/>

<table>
  <tbody>
{rows}
</tbody>
</table>
	
  
</div>
)
    }
}
// onClick={() => { callUrl('logout') }}

