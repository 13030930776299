const React = require("react")
//import { render } from "react-dom";
//import {callUrl} from "./helper"
//const Space = require("./comp_misc").compSpace
//const config = require('./config.js').CONFIG
//const Modal = require('react-modal')
const helper = require('../helper');
const TextPrompt = require("../comp_misc").textPrompt
const CompList = require("../comp_misc").compList

var g_link = "/user/wiki";

class FormWiki extends React.Component {
  constructor() {
    super();

    var q = helper.parseQuery();

  this.state = {
    id: q.id,
    project: q.project,
    name: decodeURI(q.name),
    page: null,

  };

  }


fetchPage()
{
  helper.getFromServer('/user/wiki?dw=get&project='+this.state.project+"&name="+helper.getURI(this.state.name))
  .then(res => {

//  console.log(res.result.body);

var page = res.result;

this.setState({page: page})
})
.catch(err => {
  alert(err)
})

}


componentDidMount() 
{
  this.fetchPage()

}


hic(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });

}

  render() {
    document.title = "Edit Wiki Page"

    if (this.state.page === null) {
return(
      <div>Working on it2</div>);
    } else {

    
return(
<div>
<button onClick={() => {
    helper.postToServer(g_link, {body: this.state.page, project: this.state.project, name: this.state.name})
    .then(res => {
      document.location = `/user/page?project=${this.state.project}&name=${helper.getURI(this.state.name)}`;
    })
    .catch(err => {
        alert(err);
    })
}}>Save</button> 
<button onClick={() => {
    helper.postToServer(g_link, {body: this.state.page, project: this.state.project, name: this.state.name, dw: "save"})
    .then(() => {
      alert("Did it!");
    })
    .catch(err => {
        alert(err);
    })
}}>Save Continue</button>
<button onClick={() => {
    document.location = `/user/page?project=${this.state.project}&name=${helper.getURI(this.state.name)}`;
}}>Exit</button><br/>
<br/>
<textarea value={this.state.page} onChange={this.hic.bind(this)} rows="20" cols="100" name="page">
	</textarea>
  <br/>
  <br/>
</div>

)
}
  }
}

// onClick={() => { callUrl('logout') }}

export default FormWiki
